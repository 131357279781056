<template>
  <div class="c-alpha-whitelist">
		<!-- <header id="header" class="c-alpha-whitelist__header"></header> -->
		<section class="c-alpha-whitelist__email-content">
			<div class="c-alpha-whitelist__email-container">
				<div class="c-alpha-whitelist__email-content-main">
					<div class="c-alpha-whitelist__email-content-main-centermail">
						<img src="@/assets/images/old-alpha/reset-sent.png" alt="Success" class=" c-alpha-whitelist__email-content-main-centermail-img">
						<h2 class="c-alpha-whitelist__email-content-main-centermail-title">New Address is being <span class="c-alpha-whitelist__email-content-main-centermail-title_blue">Processed</span></h2>
						<a @click='routerRedirect("/")' id="returnhome" class="c-alpha-whitelist__email-content-main-centermail-return">Return to Home</a><span class="c-alpha-whitelist__email-content-main-centermail-return_arrow"> >></span>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
}
</script>
